import React from "react";
import { useDispatch } from "react-redux";

import { MenuOutlined, LogoutOutlined } from "@ant-design/icons";

// STYLES
import { HeaderWrapper } from "./Header.style";

// REDUX
import { logOutUser } from "Redux/Auth/Actions";
import { toggleSidebar } from "Redux/General/Actions";

function Topbar() {
  const dispatch = useDispatch();
  return (
    <HeaderWrapper> 
      <div className="placeholder">
        <div
          className="sidebar-toggle"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <MenuOutlined />
        </div>
      </div>
      <div
        className="actions"
        onClick={() => {
          dispatch(logOutUser());
        }}
      >
        <LogoutOutlined className="icon" />{" "}
        <span className="icon-text">Log Out </span>
      </div>
    </HeaderWrapper>
  );
}

export default Topbar;
