import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Input, Form } from 'antd';

// STYLES
import { LoginWrapper, LoginBox, LogoWrapper } from "./Login.style";

// CONST
import { URL_DASHBOARD } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes"
import { loginUser } from "Redux/Auth/Actions";
import { Notification } from "Helpers/Notification"

// IMAGES
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import LogoImage from "Assets/Images/akareeLogo.svg";

import API from '../../../Helpers/ApiHandler'
import { LOGIN_API } from '../../../Routes/APIRouter'
let api = new API()

function Login(props) {
    let history = useHistory()
    const isAdminLoggedIn = useSelector(state => state.Auth.isAdminLoggedIn);
    const accessToken = useSelector(state => state.Auth.accessToken);
    const dispatch = useDispatch();

    useEffect(() => {
        checkIfAlreadyLoggedIn();
        // eslint-disable-next-line
    }, [])

    function checkIfAlreadyLoggedIn() {
        if (isAdminLoggedIn){
            if (props.location.search.substring(10) !== '' && props.location.search.substring(10).toLowerCase().includes("akaree.com")) {
                if (props.location.search.substring(10).substring(0, 4) === 'http')
                    window.location.href = props.location.search.substring(10) + `?authToken=${accessToken}`
                else window.location.href = props.location.search.substring(10);
            }
            history.push(URL_DASHBOARD)
        }
    }

    function login(values) {
        let body = {
            data: values
        }
        api.post(LOGIN_API, body).then((result) => {
            if (result.status === CODES.SUCCESS) {
                dispatch(loginUser(result.data))
                Notification('success', result.message)
                if (props.location.search.substring(10) !== '') {
                    console.log(props.location.search.substring(10).substring(0, 5));
                    if (props.location.search.substring(10).substring(0, 4) === 'http') window.location.href = props.location.search.substring(10) + `?authToken=${result.data.token}`
                    else window.location.href = props.location.search.substring(10);
                }
                history.push(URL_DASHBOARD)
            }
            else {
                Notification('info', result.message)
            }
        });
    }

    return (
        <LoginWrapper className="flex f-v-center f-h-center">
            <LoginBox className="box">
                <LogoWrapper>
                    <img className="img" src={LogoImage} alt="Logo" />
                </LogoWrapper>

                <Form
                    className="form-container"
                    onFinish={login}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please enter your email' }]}
                    >
                        <Input size="large" placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please enter your password' }]}
                    >
                        <Input.Password
                            placeholder="Password"
                            size="large"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <div className="button-container">
                        <Button
                            className="btn"
                            type="primary"
                            shape="round"
                            size="large"
                            htmlType="submit"
                        >
                            Login
                        </Button>
                    </div>
                </Form>
            </LoginBox>
        </LoginWrapper>
    )
}


export default Login;