export const ACTION_TYPES = {
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER: "LOGOUT_USER",
    SET_SUB_ADMIN_ROLES: "SET_SUB_ADMIN_ROLES"
}

export const loginUser = (data) => {
    // SET YOUR LOGIN INFO HERE
    localStorage.setItem('isAdminLoggedIn', true)
    localStorage.setItem('accessToken', data.token)
    localStorage.setItem('userData', JSON.stringify(data))

    return {
        type: ACTION_TYPES.LOGIN_USER,
        ...data
    }
}

export const logOutUser = () => {
    localStorage.clear();
    return {
        type: ACTION_TYPES.LOGOUT_USER,
    }
}

export const setSubAdminRoles = (data) => {
    localStorage.setItem('subAdminRoles', JSON.stringify(data))
    return {
        type: ACTION_TYPES.SET_SUB_ADMIN_ROLES,
        data: data
    }
}