import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route } from "react-router-dom";

// COMPONENTS
import Login from "Components/Pages/Login/Login";

// ROUTING COMPONENTS
import ProtectedRoute from "./ProtectedRoute";
import RoutesList from "./RoutesList";
import { URL_LOGIN } from "Helpers/Paths";
import CODES from "Helpers/StatusCodes";
import Api from "Helpers/ApiHandler";
import {
    SUB_ADMIN_ROLES_LIST_API
} from "Routes/APIRouter";

//redux
import { setSubAdminRoles } from "Redux/Auth/Actions"
const API = new Api({});
function Routes() {
    let dispatch = useDispatch()
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line
    const [rulesModules, setRulesModules] = useState({})
    const adminData = useSelector(state => state.Auth.userData)
    const [userType, setUserType] = useState(0)
    const [subAdminRouter, setSubAdminRouter] = useState([RoutesList[0], RoutesList[1]])
    useEffect(() => {
        if (loading) {
            if (adminData) {
                if (adminData._id && adminData.adminType === "ADMIN") {
                    setUserType(1)
                }
                if (adminData._id && adminData.adminType === "SUB_ADMIN") {
                    setUserType(2)
                    API.get(SUB_ADMIN_ROLES_LIST_API + adminData._id).then((result) => {
                        setLoading(false);
                        let router = [...subAdminRouter]
                                
                        if (result.status === CODES.SUCCESS) {
                            if (result.data.length) {
                                dispatch(setSubAdminRoles(result.data));
                                setRulesModules(result.data)

                                RoutesList.forEach((element) => {
                                    result.data.forEach((innerElement) => {
                                        if (element.module === innerElement.module) {
                                            if (innerElement.roles && innerElement.roles.length) {
                                                if (innerElement.roles[0].isSelected) {
                                                    router.push(element)
                                                }
                                            }
                                        }

                                    })
                                })
                                
                                
                            }else{
                                dispatch(setSubAdminRoles(null));

                            }
                           
                        }
                        RoutesList.forEach((element) => {
                            if (element.module === "SETTING") {
                              router.push(element)
                            }
                          })
                        setSubAdminRouter(router)
                    });
                }
            }
        }
        // eslint-disable-next-line
    }, [loading])
    return (
        <Switch>
            <Route path={URL_LOGIN} component={Login} />
            <ProtectedRoute>
                {
                    userType === 1 ?
                        RoutesList.map((route, index) => (
                            <React.Fragment key={index}>
                                {
                                    (route.sidebar && route.sidebar.nested) ?
                                        route.sidebar.nested.map((child, index) =>
                                            <Route
                                                key={index}
                                                path={child.path}
                                                exact={child.exact}
                                                component={child.component}
                                            />
                                        )
                                        :
                                        <Route
                                            path={route.path}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                }
                            </React.Fragment>





                        ))
                        : userType === 2 ?
                            subAdminRouter.map((route, index) => (
                                <React.Fragment key={index}>
                                    {
                                        (route.sidebar && route.sidebar.nested) ?
                                            route.sidebar.nested.map((child, index) =>
                                                <Route
                                                    key={index}
                                                    path={child.path}
                                                    exact={child.exact}
                                                    component={child.component}
                                                />
                                            )
                                            :
                                            <Route
                                                path={route.path}
                                                exact={route.exact}
                                                component={route.component}
                                            />
                                    }
                                </React.Fragment>

                            ))
                            : null
                }
            </ProtectedRoute>

        </Switch>
    );
}

export default Routes;
