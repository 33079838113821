import { ACTION_TYPES } from './Actions';

const initState = {
    isAdminLoggedIn: localStorage.getItem('isAdminLoggedIn') ? (localStorage.getItem('isAdminLoggedIn') === "true") : false,
    accessToken: localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null,
    userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null,
    subAdminRoles: localStorage.getItem('subAdminRoles') ? JSON.parse(localStorage.getItem('subAdminRoles')) : null
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_USER:

            return {
                ...state,
                isAdminLoggedIn: true,
                accessToken: action.token,
                userData: action
            };

        case ACTION_TYPES.LOGOUT_USER:
            return {
                ...state,
                isAdminLoggedIn: false
            };
        case ACTION_TYPES.SET_SUB_ADMIN_ROLES:
            return {
                ...state,
                subAdminRoles: action.data
            };

        default:
            return state;
    }

}

export default Reducer;