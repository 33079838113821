import axios from "axios";
import CODES from "./StatusCodes";
import { store } from "Redux/Store";
import { logOutUser } from "Redux/Auth/Actions";
import { showToast } from "Redux/General/Actions";

import { isIEBrowser } from "Helpers/Utils";

const METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch"
};

const BASEURL = process.env.REACT_APP_API_URL /*process.env.REACT_APP_API_URL */ //"http://localhost:3001/";
// const BASEURL = "http://localhost:3001/";

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class Api {
  isAdminLoggedIn = true;

  constructor() {
    this.baseURL = BASEURL;
    this.getAuthenticationInfo();
  }

  getAuthenticationInfo() {
    if (localStorage.getItem("isAdminLoggedIn")) {
      this.isAdminLoggedIn = true;
      this.accessToken = localStorage.getItem("accessToken");
    }
  }

  // URL FOR API
  // REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
  get(url, data, timeout) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.GET, url, data, timeout)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
        });
    });
  }

  post(url, data, timeout) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.POST, url, data, timeout)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  patch(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PATCH, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
        });
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DELETE, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
        });
    });
  }

  api(method, url, data, timeout) {
    return new Promise((resolve, reject) => {
      let axiosConfig = {};
      axiosConfig.method = method;
      if(timeout)
        axiosConfig.timeout = timeout;
      axiosConfig.url = this.baseURL + url;

      axiosConfig.headers = this.setHeaders(data);
      axiosConfig.data = data;
      if (data) {
        if (data.params) axiosConfig.params = data.params;

        if (data.data) axiosConfig.data = data.data;
      }

      if (isIEBrowser()) {
        if (!axiosConfig.params) axiosConfig.params = {};
        axiosConfig.params.time = new Date().getTime();
      }

      axios(axiosConfig)
        .then((response) => {
          resolve(response.data);
          if (response.data.status === CODES.UNAUTHORIZED) {
            store.dispatch(logOutUser());
          } else if (response.data.status === CODES.INTERNAL_SERVER_ERROR) {
            if (data && !data.skipToast)
              store.dispatch(showToast("Internal Server Error"));
            if (data && data.skipErrorHandling) reject(response.data);
          }
        })
        .catch((error) => {
          if (error && error.response) {
            if (error.response.status === CODES.UNAUTHORIZED) {
              store.dispatch(logOutUser());
            } else if (error.response.status === CODES.INTERNAL_SERVER_ERROR) {
              if (data && !data.skipToast)
                store.dispatch(showToast("Internal Server Error"));
              if (data && data.skipErrorHandling) reject(error.response);
            } else {
              resolve(error.response);
            }
          } 
        });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers["accept-language"] = "en";
    headers["Content-Type"] = "application/json";

    if (data) {
      if (data.isMultipart) {
        headers["Content-Type"] = "multipart/form-data";
      }

      if (data.headers) {
        for (var key in data.headers) {
          if (data.headers.hasOwnProperty(key)) {
            headers[key] = data.headers[key];
          }
        }
      }
    }

    if (this.isAdminLoggedIn !== false && !(data && data.skipAuth)) {
      headers["Authorization"] = this.accessToken;
    }

    return headers;
  }
}

// SAMPLE DATA JSON
/* let sample_data = {

    // ADDITIONAL HEADERS IF REQUIRED
    headers :{
        'Content-type'  : 'xx-urlencode',
    },

    // IF USER ID AND TOKEN SHOULDN'T BE PASSED IN HEADERS (USER FOR AFTER LOGIN API)
    // DEFAULT : FALSE;
    skipAuth    : false,

    // IF Default error handling needs to be overridden
    // DEFAULT : FALSE;
    skipErrorHandling    : false,

    // FOR SENDING FILES OR FORM DATA REQUEST
    isMultipart : true,

    // `PARAMS` ARE THE URL PARAMETERS TO BE SENT WITH THE REQUEST
    params : {
        user_id     : 10,
        name        : "lorem",
        page        : 3,
        sort_by     : 'name'
    },

    // POST DATA
    data : {
        firstName   : 'Lorem',
        lastName    : 'Ipsum'
    },
} */

export default Api;
