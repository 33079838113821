import styled from "styled-components";
import Background from "Assets/Images/header.png";

// THEME
import { Layout } from "antd";

// CONST
import { DIMENSIONS, responsive } from "Styles/Constants";

const { Header } = Layout;

export const HeaderWrapper = styled(Header)`
  background: url(${Background});
  box-shadow: 6px 0px 10px #ccc;
  display: flex;
  position: fixed;
  justify-content: space-between;
  width: calc(100% - ${DIMENSIONS.SIDEBAR.FULL}px);
  z-index: 1;

  ${responsive.PHABLET`width : 100%;`}

  .sidebar-toggle {
    cursor: pointer;
    display: none;
    ${responsive.PHABLET`display:block`}
  }

  .actions {
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    .icon {
      cursor: pointer;
      font-size: 16px;
    }
    .icon-text {
      line-height: 16px;
      margin-left: 5px;
    }
  }
`;
