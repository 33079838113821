import React from "react";
import LazyLoader from "@loadable/component";

import {
  URL_DASHBOARD,
  URL_ADMIN,
  URL_VENDOR,
  URL_PROPERTY,
  URL_PAGES,
  URL_SETTINGS,
  URL_MY_ACCOUNT,
  URL_CUSTOMER,
  URL_ADVERTISEMENT,
  URL_LOCATIONS,
  URL_COUNTRY,
  URL_CITY,
  URL_BANNER_REQUEST,
  URL_PROPERTY_REQUEST,
  URL_BANNER_HISTORY,
  URL_PROPERTY_HISTORY,
  URL_SUB_ADMIN,
  URL_TEMPLATE,
  URL_EMAIL_TEMPLATE,
  URL_NOTIFICATION,
  URL_CONTENT_MANAGEMENT,
  URL_BLOG,
  URL_CATEGORY,
  URL_VENDOR_TYPE,
  URL_SERVICE,
  URL_BLOG_CATEGORY,
  URL_ROLE,
  URL_PROJECT_TYPE,
  URL_SUBSCRIPTION,
  URL_PROMO_CODE,
  URL_REPORT,
  URL_BUNDLE,
  URL_BANK_DETAIL,
  URL_DISCOUNT_CODE,
  URL_PAY_AS_YOU_GO,
  URL_PAGES_FAQ,
  URL_STATIC_PAGES,
  URL_BANK_TRANSACTION_HISTORY,
  URL_TRANSACTION_HISTORY,
  URL_SURVEY_MANAGEMENT,
  URL_CALENDER,
  URL_FEEDBACK_MANAGEMENT,
  URL_INCOME_REPORT,
  URL_CLAIM_REQUESTS
} from "Helpers/Paths";

import {
  ShopOutlined,
  BarChartOutlined,
  // CloudOutlined,
  ReconciliationOutlined,
  UserOutlined,
  // AppstoreOutlined,
  NotificationOutlined,
  GlobalOutlined,
  FileTextOutlined,
  FormOutlined,
  BarsOutlined,
  SettingOutlined,
  MoneyCollectOutlined,
  AreaChartOutlined,
  MailOutlined
} from "@ant-design/icons";

export default [
  {
    path: "/",
    exact: true,
    component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
    module: "/"
  },
  {
    path: URL_DASHBOARD,
    exact: true,
    component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
    sidebar: {
      name: "Dashboard",
      icon: <BarChartOutlined />,
    },
    module: "DASHBOARD"
  },
  {
    path: URL_ADMIN,
    exact: true,
    component: LazyLoader(() => import("Components/Admins/Admins")),
    sidebar: {
      name: "Admins",
      icon: <UserOutlined />,
      nested: [
        {
          name: "Super Admin",
          path: URL_ADMIN,
          component: LazyLoader(() => import('Components/Admins/Admins')),
          module: "ADMINS"
        },
        {
          name: "Sub Admin",
          path: URL_SUB_ADMIN,
          component: LazyLoader(() => import('Components/SubAdmins/Admins')),
          module: "SUB_ADMINS"
        },
        {
          name: "Role",
          path: URL_ROLE,
          component: LazyLoader(() => import('Components/Admins/Roles/Roles')),
          module: "ROLE"
        }
      ]
    },
  },
  {
    path: URL_CUSTOMER,
    exact: true,
    component: LazyLoader(() => import("Components/Customer/Customer")),
    sidebar: {
      name: "Users",
      icon: <UserOutlined />,
    },
    module: "CUSTOMERS"
  },
  {
    path: URL_VENDOR,
    exact: true,
    component: LazyLoader(() => import("Components/Vendor/Vendor")),
    sidebar: {
      name: "Companies",
      icon: <UserOutlined />,
    },
    module: "VENDORS"
  },
  {
    path: URL_CLAIM_REQUESTS,
    exact: true,
    component: LazyLoader(() => import("Components/Vendor/ClaimRequest")),
    sidebar: {
      name: "Claim Requests",
      icon: <UserOutlined />,
    },
    module: "VENDORS"
  },
  {
    path: URL_PROPERTY,
    exact: true,
    component: LazyLoader(() => import("Components/Property/Property")),
    sidebar: {
      name: "Properties",
      icon: <ShopOutlined />,
    },
    module: "PROPERTIES"
  },
  {
    path: URL_ADVERTISEMENT,
    exact: true,
    component: LazyLoader(() => import('Components/Advertisement/BannerHistory')),
    sidebar: {
      name: "Advertisements",
      icon: <NotificationOutlined />,
      nested: [
        {
          name: "Banner Requests",
          path: URL_BANNER_REQUEST,
          component: LazyLoader(() => import('Components/Advertisement/BannerRequest')),
        },
        {
          name: "Banner History",
          path: URL_BANNER_HISTORY,
          component: LazyLoader(() => import('Components/Advertisement/BannerHistory')),
        },
        {
          name: "Property Requests",
          path: URL_PROPERTY_REQUEST,
          component: LazyLoader(() => import('Components/Advertisement/PropertyRequest')),
        },
        {
          name: "Property History",
          path: URL_PROPERTY_HISTORY,
          component: LazyLoader(() => import('Components/Advertisement/PropertyHistory')),
        },
      ]
    },
    module: "ADVERTISEMENTS"
  },
  {
    path: URL_SUBSCRIPTION,
    exact: true,
    //component: LazyLoader(() => import("Components/SubScription/PromoCode")),
    sidebar: {
      name: "Subscription",
      icon: <MoneyCollectOutlined />,
      nested: [
        {
          name: "Bundle",
          path: URL_BUNDLE,
          component: LazyLoader(() => import('Components/SubScription/Bundle/Bundle'))
        },
        {
          name: "Discount Codes",
          path: URL_DISCOUNT_CODE,
          component: LazyLoader(() => import('Components/SubScription/DiscountCode/DiscountCode'))
        },
        {
          name: "Promo Codes",
          path: URL_PROMO_CODE,
          component: LazyLoader(() => import('Components/SubScription/PromoCode/PromoCode'))
        },

        {
          name: "Reports",
          path: URL_REPORT,
          component: LazyLoader(() => import('Components/SubScription/Report/Report'))
        },
        {
          name: "Bank Detail",
          path: URL_BANK_DETAIL,
          component: LazyLoader(() => import('Components/SubScription/BankDetail/BankDetail'))
        },
        {
          name: "Transaction History",
          path: URL_TRANSACTION_HISTORY,
          component: LazyLoader(() => import('Components/SubScription/TransactionHistory/TransactionHistory'))
        },
        {
          name: "Bank Transaction History",
          path: URL_BANK_TRANSACTION_HISTORY,
          component: LazyLoader(() => import('Components/SubScription/BankTransactionHistory/BankTransactionHistory'))
        },
        {
          name: "Pay As You Go",
          path: URL_PAY_AS_YOU_GO,
          component: LazyLoader(() => import('Components/SubScription/PayAsYouGo/PayAsYouGo'))
        },
        {
          name: "Calender",
          path: URL_CALENDER,
          component: LazyLoader(() => import('Components/SubScription/Calender/Calender'))
        },
        {
          name: "Income Report",
          path: URL_INCOME_REPORT,
          component: LazyLoader(() => import('Components/SubScription/IncomeReport/IncomeReport'))
        },
      ]
    },
    module: "SUBSCRIPTION"
  },
  {
    path: URL_PAGES,
    exact: true,
    component: LazyLoader(() => import("Components/StaticPages/Pages/StaticPages")),
    sidebar: {
      name: "Static Pages",
      icon: <ReconciliationOutlined />,
      nested: [
        {
          name: "Pages",
          path: URL_STATIC_PAGES,
          component: LazyLoader(() => import("Components/StaticPages/Pages/StaticPages")),
        },
        {
          name: "FAQS",
          path: URL_PAGES_FAQ,
          component: LazyLoader(() => import("Components/StaticPages/FAQS/Faqs")),
        },
      ]
    },
    module: "PAGES"
  },
  {
    path: URL_TEMPLATE,
    exact: true,
    component: LazyLoader(() => import('Components/Template/Email/email')),
    sidebar: {
      name: "Template",
      icon: <FileTextOutlined />,
      nested: [
        {
          name: "Email",
          path: URL_EMAIL_TEMPLATE,
          component: LazyLoader(() => import('Components/Template/Email/email')),
        },
        // {
        //   name: "Cities",
        //   path: URL_NOTIFICATION_TEMPLATE,
        //   component: LazyLoader(() => import('Components/Template/City/City')),
        // }
      ]
    },
    module: "TEMPLATE"
  },
  {
    path: URL_NOTIFICATION,
    exact: true,
    component: LazyLoader(() => import("Components/Notification/Notification")),
    sidebar: {
      name: "Notification",
      icon: <NotificationOutlined />,
      showDivider: true,
    },
    module: "NOTIFICATION"
  },
  {
    path: URL_LOCATIONS,
    exact: true,
    component: LazyLoader(() => import('Components/Locations/Country/Country')),
    sidebar: {
      name: "Locations",
      icon: <GlobalOutlined />,
      nested: [
        {
          name: "Countries",
          path: URL_COUNTRY,
          component: LazyLoader(() => import('Components/Locations/Country/Country')),
        },
        {
          name: "Cities",
          path: URL_CITY,
          component: LazyLoader(() => import('Components/Locations/City/City')),
        }
      ]
    },
    module: "LOCATIONS"
  },

  {
    path: URL_CONTENT_MANAGEMENT,
    exact: true,
    component: LazyLoader(() => import('Components/ContentManagement/Blog/Blog')),
    sidebar: {
      name: "Content Management",
      icon: <FormOutlined />,
      nested: [
        {
          name: "Blogs",
          path: URL_BLOG,
          component: LazyLoader(() => import('Components/ContentManagement/Blog/Blog')),
        },
      ]
    },
    module: "CONTENT_MANAGEMENT"
  },

  {
    path: URL_CATEGORY,
    exact: true,
    component: LazyLoader(() => import('Components/Category/VendorType/VendorType')),
    sidebar: {
      name: "Category Management",
      icon: <BarsOutlined />,
      nested: [
        {
          name: "Vendor Type",
          path: URL_VENDOR_TYPE,
          component: LazyLoader(() => import('Components/Category/VendorType/VendorType')),
        },
        {
          name: "Services",
          path: URL_SERVICE,
          component: LazyLoader(() => import('Components/Category/Service/Service')),
        },
        {
          name: "Blog Category",
          path: URL_BLOG_CATEGORY,
          component: LazyLoader(() => import('Components/Category/BlogCategory/BlogCategory')),
        }
      ]
    },
    module: "CATEGORY"
  },
  {
    path: URL_PROJECT_TYPE,
    exact: true,
    component: LazyLoader(() => import("Components/ProjectType/ProjectType")),
    sidebar: {
      name: "Project Types",
      icon: <BarsOutlined />,
      showDivider: true,
    },
    module: "PROJECT_TYPE"
  },
  {
    path: URL_SURVEY_MANAGEMENT,
    exact: true,
    component: LazyLoader(() => import("Components/SurveyManagement/Survey")),
    sidebar: {
      name: "Survey Management",
      icon: <AreaChartOutlined />,
      showDivider: true,
    },
    module: "SURVEY_MANAGEMENT"
  },
  {
    path: URL_FEEDBACK_MANAGEMENT,
    exact: true,
    component: LazyLoader(() => import("Components/Feedbacks/Feedbacks")),
    sidebar: {
      name: "Feedback Management",
      icon: <MailOutlined />,
      showDivider: true,
    },
    module: "FEEDBACK_MANAGEMENT"
  },
  {
    path: URL_SETTINGS,
    exact: true,
    component: LazyLoader(() => import('Components/MyAccount/MyAccount')),
    sidebar: {
      name: "Settings",
      icon: <SettingOutlined />,
      nested: [
        {
          name: "My Account",
          path: URL_MY_ACCOUNT,
          component: LazyLoader(() => import('Components/MyAccount/MyAccount')),
        }
      ],

    },
    module: "SETTING"
  },
];
