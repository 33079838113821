import { createGlobalStyle } from 'styled-components'
import { FONTS, responsive } from "./Constants";

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }
    html,
    body{
        margin: 0;
        padding: 0;
    }
    body{
        background-color    : #f0f2f5;
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
    }
    #root {
        display: flex;
    }
    .full-height{
        height: 100%;
    }
    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }
    .f-v-center{
        align-items: center;
    }
    .f-h-center{
        justify-content: center;
    }

    .text-center{
        text-align: center;
    }

    .visible-md{
        display: none;
        ${responsive.TABLET`display:block`}
    }
    .hidden-md{
        ${responsive.TABLET`display:none`}
    }
    .plain-notification{
        background-color: #fff;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }

    .ant-table-body { 
        overflow-x: auto !important
    } 
    .ant-btn {
        min-width       : 100px;
    }

    .ant-notification-notice-with-icon{
        display:flex;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-description{
        margin-left:0px;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-message{
        margin-left:28px;
    }
    .error-sign{
        text-align: end;
        padding-right: 4px;
        color: red;
        font-size: 17px;
        font-weight: 600;
    }
    .error-msg{
        color:#ff4d4f;
        margin-bottom:0px;
    }
    .view-vendor{
        div{
            margin:5px 0px;
        }
    }
    input::placeholder,textarea::placeholder {
    color: rgba(0, 0, 0, 0.50) !important;
    
  }
  .ant-select-selection-placeholder{
    color: black !important;  
  }
  /* .ant-select-selection__placeholder{
  color : blue !important;
} */
    .phone-error{
      color:#ff4d4f;
      margin-bottom:0px;
      text-align:left;
      position:absolute;
      transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    
    .intl-tel-input input:focus{
        border: 1px solid #1890ff !important;
    
    }
    .intl-tel-input{
        width:100%;
        input{
          width:100%;
          padding:  6.5px 11px 6.5px 50px !important;
          background-color: #fff;
          background-image: none;
          border: 1px solid #d9d9d9 !important;
          border-radius: 5px;

        }
        .country-list{
          width:100%;
          overflow-x:hidden;
        }
    }
     .ant-form-item-has-error input:hover{
      background:white;
      box-shadow:none;
      border-color: #F5F5F5;
    
    } 
    
    .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover,.ant-form-item-has-error .ant-input:not([disabled]):hover{
        border-color: #F5F5F5;
    
    }
    .ant-form-item-has-error .ant-input-affix-wrapper:not([disabled]):hover,.ant-form-item-has-error .ant-input:focus{
        border-color:#1890ff;
    }
     .ant-form-item-has-error input{
        box-shadow:none !important;
        border:1px solid #F5F5F5;
        background:#F5F5F5;
    }
    .ant-notification-close-x{
        position:absolute;
        right:-8px;
    } 
    .ant-pagination-options{
        display:none;
    }
`;
