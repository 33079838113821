export const LOGIN_API = "admins/admin-login";
export const ADMIN_LIST_API = "admins/admin-list";
export const ADMIN_DETAIL_API = "admins/get-admin-detail";
export const UPDATE_ADMIN_DETAIL_API = "admins/update-admin";
export const ADMIN_DASHBOARD_DETAIL_API = "admins/admin-dashboard-detail";

// sub admin api
export const SUB_ADMIN_LIST_API = "admins/sub-admin-list";
export const SUB_ADMIN_CREATE_API = "admins/create-sub-admin";
export const ADD_SUB_ADMIN_ROLES_API = "admins/add-sub-admin-roles";
export const SUB_ADMIN_ROLES_LIST_API = "admins/sub-admin-roles-list/";
export const GET_SUB_ADMIN_DETAIL_API = "admins/get-sub-admin-detail/";
export const DELETE_SUB_ADMIN_API = "admins/sub-admin-delete/";
export const UPDATE_SUB_ADMIN_API = "admins/update-sub-admin";
export const UPDATE_ADMIN_PASSWORD_API = "admins/update-sub-admin-password";

//project api
export const PROJECT_LIST_API = "admins/vendor-project-list";
export const PROJECT_TYPE_LIST_API = "admins/project-type-list";
export const PROJECT_EDIT_VERIFIED_API = "admins/vendor-edit-verified";
export const VIEW_PROJECT_API = "admins/vendor-view-project/";
export const GET_PROJECT_OTHER_DETAIL_API =
	"admins/get-vendor-project-other-detail";
export const EDIT_VENDOR_PROJECT_API = "admins/vendor-edit-project";
export const PROJECT_ADVERTISE_REQUEST_APPROVE_API =
	"admins/vendor-property-advertise-status-change";
export const PROJECT_EDIT_FEATURED_API = "admins/vendor-edit-property-featured";
export const PROJECT_EDIT_DATE_RANGE_API =
	"admins/vendor-edit-property-date-range";

//vendor api
export const ADMIN_CREATE_API = "admins/create-admin";
export const VENDOR_LIST_API = "admins/vendor-list";
export const VENDOR_CREATE_API = "admins/add-vendor";
export const VENDOR_EDIT_FEATURED_API = "admins/update-featured";
export const VENDOR_EDIT_PAID_API = "admins/update-paid";
export const VENDOR_EDIT_PREFERENCE_API = "admins/update-preference";
export const VENDOR_DELETE_API = "admins/remove-vendor/";
export const GET_VENDOR_DETAIL_API = "admins/get-vendor-detail/";
export const CREATE_USER_SERVICE_LIST_API = "admins/create-user-service-list";
export const CREATE_USER_PROJECT_TYPE_LIST_API =
	"admins/create-user-project-type-list";
export const VENDOR_EDIT_API = "admins/update-vendor";
export const UPDATE_VENDOR_VERIFIED_API = "admins/update-verified";
export const UPDATE_VENDOR_ACTIVE_API = "admins/update-vendor-active";
export const BULK_IMPORT_VENDOR_API_OLD = "admins/add-bulk-vendor";
export const BULK_IMPORT_VENDOR_API = "admins/companies/bulk-import";
export const VENDOR_RATING_REVIEW_LIST_API = "users/vendor-rate-review-list";
export const RESET_VENDOR_PASSWORD = "admins/reset-vendor-password";

//customer api
export const CUSTOMER_LIST_API = "admins/customer-list";
export const GET_CUSTOMER_DETAIL_API = "admins/get-customer-detail/";
export const CUSTOMER_EDIT_API = "admins/update-customer";
export const CUSTOMER_DELETE_API = "admins/remove-customer/";
export const CLIENT_PROJECT_API = "admins/customer-projects";

//advertisement api
export const ADVERTISEMENT_LIST_API = "admins/advertisement-list";
export const ADVERTISEMENT_CREATE_API = "admins/add-advertisement";
export const GET_ADVERTISEMENT_DETAIL_API = "admins/get-advertisement-detail/";
export const ADVERTISEMENT_EDIT_API = "admins/update-advertisement";
export const ADVERTISEMENT_DELETE_API = "admins/remove-advertisement/";
export const GET_VENDOR_DROPDOWN_LIST_API = "admins/vendor-dropdown-list/";
export const GET_VENDOR_PROJECT_DROPDOWN_LIST_API =
	"admins/vendor-project-dropdown-list/";
export const BANNER_REQUEST_LIST_API = "admins/banner-request-list";
export const PROJECT_FEATURED_REQUEST_LIST_API =
	"admins/project-featured-request-list";
export const VENDOR_CHANGE_PAID_REQUEST_API =
	"admins/vendor-change-paid-banner-request";
export const PROJECT_REQUEST_HISTORY_API = "admins/project-request-history";
export const VENDOR_CHANGE_APPROVED_REQUEST_API =  'admins/vendor-approve-banner-request'

//location api
export const COUNTRY_SEARCH_LIST_API = "admins/country-search-list";
export const CITY_SEARCH_LIST_API = "admins/city-search-list";
export const CREATE_COUNTRY_API = "admins/create-country";
export const EDIT_COUNTRY_API = "admins/update-country";
export const CREATE_CITY_API = "admins/create-city";
export const EDIT_CITY_API = "admins/update-city";
export const GET_CITY_DETAIL_API = "admins/get-city-detail";
export const REMOVE_CITY_API = "admins/remove-city/";
export const REMOVE_COUNTRY_API = "admins/remove-country/";

//content management api
export const BLOG_SEARCH_LIST_API = "admins/blog-list";
export const CREATE_BLOG_API = "admins/add-blog";
export const EDIT_BLOG_API = "admins/update-blog";
export const GET_BLOG_DETAIL_API = "admins/get-blog-detail";
export const REMOVE_BLOG_API = "admins/delete-blog/";
export const BLOG_FEATURED_API = "admins/update-feature-blog";

//category api
export const VENDOR_TYPE_SEARCH_LIST_API = "admins/vendor-type-search-list";
export const SERVICE_SEARCH_LIST_API = "admins/service-search-list";
export const CREATE_VENDOR_TYPE_API = "admins/create-vendor-type";
export const EDIT_VENDOR_TYPE_API = "admins/update-vendor-type";
export const CREATE_SERVICE_API = "admins/create-service";
export const EDIT_SERVICE_API = "admins/update-service";
export const GET_SERVICE_DETAIL_API = "admins/get-service-detail";
export const REMOVE_SERVICE_API = "admins/remove-service/";
export const REMOVE_VENDOR_TYPE_API = "admins/remove-vendor-type/";
export const CREATE_BLOG_CATEGORY_API = "admins/create-blog-category";
export const BLOG_CATEGORY_SEARCH_LIST_API = "admins/blog-category-search-list";
export const ALL_BLOG_CATEGORY_LIST_API = "admins/all-blog-category-list";
export const UPDATE_BLOG_CATEGORY_API = "admins/update-blog-category";
export const DELETE_BLOG_CATEGORY_API = "admins/remove-blog-category/";

export const NOTIFICATION_LIST_API = "admin-notification-list";
export const NOTIFICATION_COUNT_API = "admin-notification-count";
export const READ_NOTIFICATION_API = "admin-read-notification";

//template api

export const EMAIL_TEMPLATE_LIST_API = "admins/template-list";
export const GET_EMAIL_TEMPLATE_DETAIL_API = "admins/template-detail";
export const UPDATE_EMAIL_TEMPLATE_API = "admins/update-template-detail";

//broad cast notification list

export const ALL_VENDOR_BROADCAST_LIST_API = "admins/all-vendor-broadcast-list";
export const ALL_CLIENT_BROADCAST_LIST_API = "admins/all-client-broadcast-list";
export const CREATE_BROADCAST_NOTIFICATION_API =
	"admins/create-broadcast-notification";
export const BROADCAST_LIST_API = "admins/broadcast-list";
export const REMOVE_BROADCAST_API = "admins/remove-broadcast/";
export const VIEW_BROADCAST_API = "admins/view-broadcast/";
export const VIEW_BROADCAST_DETAIL_API = "admins/view-broadcast-detail/";

// admin roles api

export const CREATE_ROLE_API = "admins/create-admin-role-type";
export const ROLE_SEARCH_LIST_API = "admins/admin-role-type-search-list";
export const ALL_ROLE_LIST_API = "admins/all-admin-role-type-list";
export const UPDATE_ROLE_API = "admins/update-admin-role-type";
export const DELETE_ROLE_API = "admins/remove-admin-role-type/";

//project type api

export const CREATE_PROJECT_TYPE_API = "admins/create-project-type";
export const PROJECT_TYPE_SEARCH_LIST_API = "admins/project-type-search-list";
export const UPDATE_PROJECT_TYPE_API = "admins/update-project-type";
export const DELETE_PROJECT_TYPE_API = "admins/remove-project-type/";

//subscription

//bundle
export const ALL_BUNDLE_LIST_API = "admins/all-bundle-list";

//promo code
export const PROMO_CODE_LIST_API = "admins/promo-code-list";
export const ADD_PROMO_CODE_API = "admins/add-promo-code";
export const EDIT_PROMO_CODE_API = "admins/update-promo-code";
export const GET_PROMO_CODE_DETAIL_API = "admins/get-promo-code-detail";
export const REMOVE_PROMO_CODE_API = "admins/remove-promo-code-detail/";

//discount code
export const BASE_CITY_LIST_API = "city-list?id="+ process.env.REACT_APP_COUNTRY_ID //5fd6f4824528fc3b707e7db5" ///*process.env.REACT_APP_COUNTRY_ID*/ /* local: 5fd8b56a999a8b3a2cf30397 */  //staging: 5fd8b56a999a8b3a2cf30397
export const DISCOUNT_CODE_LIST_API='admins/discount-code-list'
export const ADD_DISCOUNT_CODE_API='admins/add-discount-code'
export const EDIT_DISCOUNT_CODE_API='admins/update-discount-code'
export const GET_DISCOUNT_CODE_DETAIL_API='admins/get-discount-code-detail'
export const REMOVE_DISCOUNT_CODE_API='admins/remove-discount-code-detail/'

//bundle
export const ADD_BUNDLE_API = "admins/add-bundle";
export const BUNDLE_LIST_API = "admins/bundle-list";
export const GET_BUNDLE_DETAIL_API = "admins/get-bundle-detail";
export const EDIT_BUNDLE_API = "admins/update-bundle";
export const REMOVE_BUNDLE_API = "admins/remove-bundle/";
export const UPDATE_BUNDLE_ACTIVE_API = "admins/update-bundle-active";

//reports
export const REPORT_SEARCH_LIST_API = "admins/report-list";
export const CREATE_REPORT_API = "admins/add-report";
export const EDIT_REPORT_API = "admins/update-report";
export const GET_REPORT_DETAIL_API = "admins/get-report-detail";
//export const REMOVE_REPORT_API = 'admins/delete-report/'
export const REMOVE_REPORT_API = "admins/remove-report/";

//bank detail
export const GET_BANK_DETAIL_API = "admins/get-bank-detail";
export const ADD_BANK_DETAIL_API = "admins/add-bank-detail";

//pay as you go detail
export const GET_PAY_AS_YOU_GO_DETAIL_API = "admins/get-pay-as-you-go-detail";
export const EDIT_PAY_AS_YOU_GO_API = "admins/edit-pay-as-you-go";

//static pages
export const STATIC_PAGE_API = "admins/staticPage-list";
export const GET_STATIC_PAGE_API = "admins/staticPage-detail/";
export const EDIT_STATIC_PAGE_API = "admins/update-staticPage";

//faq pages
export const FAQ_PAGE_API = 'admins/faq-list';
export const FAQ_PAGE_GET_API = 'admins/faq-detail/';
export const FAQ_PAGE_ADD_API = 'admins/add-faq';
export const FAQ_PAGE_UPDATE_API = 'admins/update-faq';
export const FAQ_PAGE_DELETE_API = 'admins/faq-delete/';

//transaction history
export const TRANSACTION_HISTORY_LIST_API = 'admins/transaction-history';
export const BANK_TRANSACTION_HISTORY_LIST_API = 'admins/bank-transaction-history';

//survey management
export const SURVEY_ADD_API = "admins/add-survey";
export const SURVEY_GET_LIST_API = "admins/get-survey";
export const SURVEY_GET_RESPONSE_API = "admins/get-survey-response";
export const SURVEY_RESPONSE_STATS = "admins/get-survey/stats/";

export const GET_BANK_TRANSFER_DETAIL_API = 'admins/get-subscription-detail';
export const ADD_BUNDLE_FOR_BANK_TRANSFER = 'admins/add-bundle-for-bank-transfer';
export const RESCHEDULE_SUBSCRIPTION = 'admins/reschedule-subscription';


//calender
export const GET_AVAILABILITY_DATA_API='admins/get-availability-data'
export const VIEW_USER_SUBSCRIPTION_FOR_DAY='admins/view-users-subscribed-for-the-day'

// income report
export const INCOME_REPORT_API = 'admins/income-report';
export const INCOME_BUNDLE_LIST_API = 'admins/income-bundle-list';

//feedbacks
export const FEEDBACK_LIST_API = 'admins/feedback-list';
//refund
export const CREATE_SUBSCRIPTION_REFUND_API = 'admins/subscription-refund';

//income chart
export const GET_INCOME_REPORT = "admins/income-report-chart";


//companies
export const CLAIM_REQUESTS = "admins/companies/claim-requests";
export const GET_COMPANIES_LIST = "admins/companies";
export const COMPANY_DETAILS = "admins/companies/company-details";
export const NEW_COMPANY_API = "admins/companies/new-company";
export const UPDATE_COMPANY_PREFRENCE = "admins/companies/update-preference";
export const UPDATE_COMPANY_ACTIVATED = "admins/companies/update-activated";
export const UPDATE_COMPANY_VERIFIED = "admins/companies/update-verified";
export const REMOVE_COMPANY = "admins/companies/remove-company/";