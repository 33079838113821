import styled from "styled-components";

// THEME
import { Layout } from "antd";
import { Menu } from "antd";

// CONST
import { DIMENSIONS, responsive } from "Styles/Constants";
const { Sider } = Layout;

export const SidebarWrapper = styled(Sider)`
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  ${responsive.PHABLET`
        position : fixed;
        display : none;
        left : 0;
        z-index : 10;

        &.active {
            display: block;
        }
    `}

  .close-container {
    display: none;
    color: #000;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    ${responsive.PHABLET`display:block`}
  }
`;

export const LogoWrapper = styled.div`
  height: ${DIMENSIONS.HEADER}px;

  .img {
    width: 100%;
    padding: 0.2em 0.75em;
  }
  .title {
    color: #fff;
  }
`;

export const MenuStyled = styled(Menu)`
  border: 0;
`;

const MenuStyle = `
    font-size : 16px;
    transition  : .3s;

    .anticon {
        font-size: 16px;
    }
    &::after {
        right : 1px;
    }

    .ant-menu:not(.ant-menu-horizontal) &.ant-menu-item-selected,
    .ant-menu:not(.ant-menu-horizontal) &.ant-menu-item:active,
    .ant-menu:not(.ant-menu-horizontal) &.ant-menu-submenu-active,
    .ant-menu-submenu-title:active {
        background-color    : #EEEEEE;
    }
`;
export const MenuItem = styled(Menu.Item)`
  ${MenuStyle}
`;

export const SubMenu = styled(Menu.SubMenu)`
  ${MenuStyle}
`;
