export const URL_DASHBOARD = "/dashboard";
export const URL_VENDOR_OLD = "/vendors";
export const URL_VENDOR = "/companies";
export const URL_PROPERTY = "/properties";
export const URL_CUSTOMER = "/customers";
export const URL_ADMIN = "/admins";
export const URL_LOGIN = "/login";
export const URL_STAFF_MEMBERS = "/staff-members";
export const URL_SETTINGS = "/settings";
export const URL_MY_ACCOUNT = "/settings/my-account";
export const URL_PAGES = "/static-pages";
export const URL_STATIC_PAGES = "/static-pages/pages";
export const URL_PAGES_FAQ = "/static-pages/faqs";
export const URL_ADVERTISEMENT = "/advertisements";
export const URL_LOCATIONS="/locations"
export const URL_COUNTRY="/locations/countries"
export const URL_CITY="/locations/cities"
export const URL_CONTENT_MANAGEMENT="/content-management"
export const URL_BLOG="/content-management/blogs"
export const URL_ARTICLE="/content-management/articles"
export const URL_BANNER_REQUEST="/banner-request"
export const URL_PROPERTY_REQUEST="/property-request"
export const URL_BANNER_HISTORY="/banner-history"
export const URL_PROPERTY_HISTORY="/property-history"
export const URL_SUB_ADMIN="/sub-admins"
export const URL_TEMPLATE=""
export const URL_EMAIL_TEMPLATE="/template/email"
export const URL_NOTIFICATION="/notification-broadcast"
export const URL_CATEGORY="/category"
export const URL_VENDOR_TYPE="/category/vendor-type"
export const URL_SERVICE="/category/services"
export const URL_BLOG_CATEGORY="/category/blog-category"
export const URL_ROLE="/roles"
export const URL_PROJECT_TYPE="/project-type"
export const URL_SUBSCRIPTION="/subscription"
export const URL_DISCOUNT_CODE='/discount-code'
export const URL_PROMO_CODE='/promo-code'
export const URL_REPORT='/report'
export const URL_BUNDLE='/bundle'
export const URL_BANK_DETAIL='/bank-detail'
export const URL_PAY_AS_YOU_GO='/pay-as-you-go'
export const URL_BANK_TRANSACTION_HISTORY='/bank-transaction-history'
export const URL_TRANSACTION_HISTORY='/transaction-history'
export const URL_SURVEY_MANAGEMENT='/survey-management'
export const URL_CALENDER='/calender'
export const URL_FEEDBACK_MANAGEMENT='/feedback-management'
export const URL_INCOME_REPORT='/income-report'
export const URL_CLAIM_REQUESTS="/claim-requests"
