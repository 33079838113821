import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// STYLES
import {
  SidebarWrapper,
  MenuStyled,
  MenuItem,
  SubMenu,
  LogoWrapper,
} from "./Sidebar.style";

// IMAGES
import LogoImage from "Assets/Images/akareeLogo.svg";

// CONST
import Routes from "Routes/RoutesList";
import { DIMENSIONS } from "Styles/Constants";
import { toggleSidebar } from "Redux/General/Actions";
import { LeftOutlined } from "@ant-design/icons";

function Sidebar(props) {
  let [routes, setRoutes] = useState([]);
  // eslint-disable-next-line
  let [loading, setLoading] = useState(true)
  let [userType, setUserType] = useState(0)
  let [subAdminRouter, setSubAdminRouter] = useState([])
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => state.General.sidebarOpen);
  const adminData = useSelector((state) => state.Auth.userData);
  const adminRoles = useSelector((state) => state.Auth.subAdminRoles);

  useEffect(() => {
    // if (loading) {
    if (adminData._id && adminData.adminType === "ADMIN")
      setUserType(1)
    else if (adminData._id && adminData.adminType === "SUB_ADMIN") {
      setUserType(2)
      let filtered = Routes.filter((route) => route.sidebar);
      setRoutes(filtered);
      if (filtered.length) {
        let router = []
        filtered.forEach((element) => {
          if (element.module === "DASHBOARD") {
            router.push(element)
          }
        })
        if (adminRoles && adminRoles.length) {
          filtered.forEach((element) => {
            adminRoles.forEach((innerElement,index) => {
              if (element.module === innerElement.module) {
                if (innerElement.roles && innerElement.roles.length) {
                  if (innerElement.roles[0].isSelected) {
                    router.push(element)
                  }
                }
              }

            })
          })
        }
        filtered.forEach((element) => {
          if (element.module === "SETTING") {
            router.push(element)
          }
        })
        setSubAdminRouter(router)
      }
      setLoading(false)
      // }
    }
    // eslint-disable-next-line
  }, [adminRoles])

  useEffect(() => {
    let filtered = Routes.filter((route) => route.sidebar);
    setRoutes(filtered);

  }, []);

  function listItemClick(route) {
    props.history.push(route.path);
  }
  return (
    <SidebarWrapper
      className={isOpen ? "active" : ""}
      width={DIMENSIONS.SIDEBAR.FULL}
    >
      <LogoWrapper className="flex f-v-center f-h-center">
        <img className="img" src={LogoImage} alt="Logo" />
      </LogoWrapper>
      <MenuStyled
        mode="inline"
        defaultSelectedKeys={[props.history.location.pathname]}
      >
        {
          userType === 1 ?
            routes.map((route) => {
              return route.sidebar.nested ? (
                <SubMenu
                  key={route.path}
                  icon={route.sidebar.icon}
                  title={route.sidebar.name}
                >
                  {route.sidebar.nested.map((child) => (
                    <MenuItem
                      key={child.path}
                      onClick={() => {
                        listItemClick(child);
                      }}
                    >
                      {child.name}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem
                  key={route.path}
                  icon={route.sidebar.icon}
                  onClick={() => {
                    listItemClick(route);
                  }}
                >
                  {route.sidebar.name}
                </MenuItem>
              );
            })
            :
            userType === 2 ?
              subAdminRouter.map((route) => {
                return route.sidebar.nested ? (
                  <SubMenu
                    key={route.path}
                    icon={route.sidebar.icon}
                    title={route.sidebar.name}
                  >
                    {route.sidebar.nested.map((child) => (
                      <MenuItem
                        key={child.path}
                        onClick={() => {
                          listItemClick(child);
                        }}
                      >
                        {child.name}
                      </MenuItem>
                    ))}
                  </SubMenu>
                ) : (
                  <MenuItem
                    key={route.path}
                    icon={route.sidebar.icon}
                    onClick={() => {
                      listItemClick(route);
                    }}
                  >
                    {route.sidebar.name}
                  </MenuItem>
                );
              }) : null

        }
      </MenuStyled>
      <div
        className="close-container text-center"
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        <LeftOutlined />
      </div>
    </SidebarWrapper>
  );
}

export default withRouter(Sidebar);
